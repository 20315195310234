.canvasStyle {
	position: relative;
	display: grid;
	grid-template-columns: 2fr 3fr;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	background-color: #abc5cc;
	transition: width 2s, height .25s, transform .25s;
	place-items : center;
}.left {
	grid-column-start: 1;
	grid-column-end: 2;
	width: 100%;
	height: 100%;
	position: relative;
	transition: width .25s, height .25s, transform .25s;
	z-index: 3;
}.middle {
	grid-column-start: 2;
	grid-column-end: 3;
	width: 100%;
	height: 100%;
	position: relative;
}.center {
	display: grid;
	place-items: center;
}.picture {
	height: 100vh;
	width: auto;
	position: absolute;
}.merge {
	height: 10vh;
	width: 10vh;
	border-radius: 50%;
	bottom: 1vh;
	right: 1vh;
	position: absolute;
	background-size: 100% 100%;
	background-color: #9dc9ba;
}.reset {
	height: 10vh;
	width: 10vh;
	border-radius: 50%;
	bottom: 1vh;
	position: absolute;
	right: 12vh;
	background-size: 100% 100%;
	background-color: #ccabab;
}.webtoon {
	height: 10vh;
	width: 10vh;
	border-radius: 50%;
	bottom: 1vh;
	position: absolute;
	right: 24vh;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 80% 80%;
	background-color: #FFFFFF;
}.patreon {
	height: 10vh;
	width: 10vh;
	border-radius: 50%;
	bottom: 1vh;
	position: absolute;
	right: 36vh;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 75% 75%;
	background-color: #FFFFFF;
}.linktree {
	height: 10vh;
	width: 10vh;
	border-radius: 50%;
	bottom: 1vh;
	position: absolute;
	right: 48vh;
	background-size: 100% 100%;
	background-color: #ccabab;
}.middleModal {
	position: absolute;
	height: 100%;
	width: 100%;
	position: absolute;
	background-color: rgba(165, 165, 165, .8);
	z-index: 4;
}