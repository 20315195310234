.leftMenu {
	display: grid;
	place-items: center;
}
.leftMenu .boundingBox{
	height: 90%;
	width: 90%;
	border-radius: 1vh;
	display: grid;
	place-items: center;
	position: relative;
	/*overflow: hidden;*/
}.categoryBox{
	background-color: rgba(207, 166, 175,1);
	height: 100%;
	width: auto;
	border-radius: 1vh;
	display: grid;
	place-items: center;
	position: absolute;
	transition : transform .25s;
}.categoryBox.catSelected {
	transform: translate(10vw);
	transition : transform .25s;
}

.category.icon {
	background-size: cover;
	width: 10vh;
	height: 10vh;
	transition: width .25s height .25s transform .25s;
	background-color: rgba(243,212,210, 1.0);
	margin-top: .5vw;
	border-radius: 50%;
	transition : transform .25s;
} .category.icon.catSelected {
	transform: translate(30%);
} .category.icon.catSelected:hover {
	transform: translate(30%) scale(1.5) rotate(25deg);
} .category.icon.catSelected.onTop {
	transform: translate(30%);
} .category.icon.catSelected.onTop:hover {
	transform: translate(30%) scale(1.5) rotate(25deg);
}

.variant.menu {
	height: 100%;
	width: 0%;
	position: absolute;
	justify-self: left;
	border-radius: 1vh;
	background-color: rgba(243,212,210,1);
	/*overflow-y: scroll;*/
	transition: width .25s;
} .variant.menu.catSelected {
	width: 90%;
	height: 100%;	
	position: absolute;
	transition: width .25s;
	justify-self: left;
	border-radius: 1vh;
	display: grid;
	place-items: center;
	overflow: hidden;
	/*overflow-y: scroll;*/
} .variant.menu.catSelected.linked{
	grid-template-columns: 1fr 1fr;
}.variant.icon {
	background-size: cover;
	width: 10vw;
	height: 10vw;
	transition: width .25s height .25s transform .25s;
	background-color: rgba(207, 166, 175,.5);
	margin-top: 2vh;
	border-radius: 50%;
	transition : transform .25s;
}.closeButton {
	position: absolute;
	width: 10vh;
	max-width: 8vw;
	height: 10vh;
	max-height: 8vw;
	background-color: red;
	z-index: 2;
	left : -2vh;
	top : -2vh;
	border-radius: 50%;
	position: absolute;
	background-size: 100% 100%;
	background-color: #ccabab;
}
/*.moveit {
	transform: translateY(90vh)
}*/

